// third-party

// assets
import {ScheduleOutlined} from '@ant-design/icons'
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"


// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const AppointmentMenu = {
    id: 'appointmentsRoot',
    title: 'APPOINTMENTS',
    icon: ScheduleOutlined,
    type: 'group',
    children: [
        {
            id: 'schedule',
            title: 'Schedule',
            type: 'item',
            url: '/schedule',
            breadcrumbs: false,
            icon: EventAvailableOutlinedIcon
        },
        {
            id: 'appointment',
            title: 'Calendar',
            type: 'item',
            url: '/appointment',
            breadcrumbs: false,
            icon: CalendarMonthOutlinedIcon
        }
    ]
}

export default AppointmentMenu