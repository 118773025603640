import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"

const ReceptionistMenu = {
    id: 'receptionistZoneRoot',
    title: 'RECEPTIONIST ZONE',
    type: 'group',
    children: [
        {
            id: 'cashAccounting',
            title: 'Cash Accounting',
            type: 'item',
            url: '/cashAccounting',
            icon: CurrencyExchangeOutlinedIcon,
            breadcrumbs: false
        }
    ]
}
export default ReceptionistMenu