// project import
// import pages from './pages'
import AppointmentMenu from "./appointment-menu"
import DashboardMenu from "./dashboard-menu"
import FinanceMenu from "./finance-menu"
import OrderMenu from "./order-menu"
import LicenceMenu from "./licence-menu"
import ClientMenu from "./client-menu"
import StaffMenu from "./staff-menu"

import ServiceMenu from "./service-menu"
import WageMenu from "./wage-menu"
import ReceptionistMenu from "./receptionist-menu"
import ManagerZoneMenu from "./managerzone-menu"
import SystemSettingMenu from "./system-setting-menu"
import LogoutMenu from "./logout-menu"

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
      DashboardMenu,
      AppointmentMenu,
      FinanceMenu,
      OrderMenu,
      LicenceMenu,
      ClientMenu,
      StaffMenu,
      ServiceMenu,
      WageMenu,
      ReceptionistMenu,
      ManagerZoneMenu,
      SystemSettingMenu,
      LogoutMenu
  ]
}

export default menuItems
