import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import GavelIcon from "@mui/icons-material/Gavel"

const LicenceMenu = {
    id: 'licenceRoot',
    title: 'LICENCE',
    type: 'group',
    children: [
        {
            id: 'receipts',
            title: 'Receipts',
            type: 'item',
            url: '/receipts',
            icon: ReceiptLongIcon
        },
        {
            id: 'licences',
            title: 'Licences',
            type: 'item',
            url: '/licences',
            icon: GavelIcon
        },
        {
            id: 'licenceBoard',
            title: 'Licences',
            type: 'item',
            url: '/licenceBoard',
            icon: GavelIcon
        }
    ]
}

export default LicenceMenu