
import {
    DashboardOutlined
} from '@ant-design/icons'
// icons
const icons = {
    DashboardOutlined
}
const DashboardMenu = {
    id: 'dashboardRoot',
    title: 'Dashboard',
    type: 'group',
    url: '/dashboard',
    icon: icons.DashboardOutlined
}

export default DashboardMenu