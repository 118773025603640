import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'

const SystemSettingMenu = {
    id: 'SystemSettingRoot',
    title: 'System Setting',
    type: 'group',
    children: [
        {
            id: 'systemSettingScheduleSort',
            title: 'Schedule Sort',
            type: 'item',
            url: '/systemSetting/scheduleSort',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'systemSettingCreditType',
            title: 'Account Type',
            type: 'item',
            url: '/systemSetting/creditType',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'systemSettingUserPermission',
            title: 'Staff Permission',
            type: 'item',
            url: '/systemSetting/userPermission',
            icon: SettingsSuggestOutlinedIcon
        }
    ]
}
export default SystemSettingMenu