import MoneyOffCsredOutlinedIcon from '@mui/icons-material/MoneyOffCsredOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'

const ManagerZoneMenu = {
    id: 'managerZoneRoot',
    title: 'MANAGER ZONE',
    type: 'group',
    children: [
        {
            id: 'expenses',
            title: 'Expense',
            type: 'item',
            url: '/expenses',
            icon: MoneyOffCsredOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'sales',
            title: 'Recent sales',
            type: 'item',
            url: '/sales',
            icon: PaymentsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'managerReport',
            title: 'Financial Reports',
            type: 'item',
            url: '/managerReport',
            icon: BarChartOutlinedIcon,
            breadcrumbs: false
        }
    ]
}
export default ManagerZoneMenu