import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"

const ClientMenu = {
    id: 'clientRoot',
    title: 'CLIENTS',
    type: 'group',
    icon: PermIdentityIcon,
    children: [
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            icon: PersonOutlineIcon,
            url: '/clients'
        },
        {
            id: 'clientHistoryRoot',
            title: 'Client History',
            type: 'collapse',
            icon: PersonOutlineIcon,
           // url: '/clients',
            children: [
                {
                    id: 'treatmentHistory',
                    title: 'Treatment History',
                    type: 'item',
                    url: '/treatmentHistory'
                },
                {
                    id: 'orderHistory',
                    title: 'Order History',
                    type: 'item',
                    url: '/orderHistory'
                },
                {
                    id: 'receiptHistory',
                    title: 'Receipt History',
                    type: 'item',
                    url: '/receiptHistory'
                },
                {
                    id: 'billHistory',
                    title: 'Bill History',
                    type: 'item',
                    url: '/billHistory'
                },
                {
                    id: 'paymentHistory',
                    title: 'Payment History',
                    type: 'item',
                    url: '/paymentHistory'
                },
                {
                    id: 'creditHistory',
                    title: 'Account History',
                    type: 'item',
                    url: '/creditHistory'
                }
            ]
        }
    ]
}
export default ClientMenu