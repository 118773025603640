import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"

const OrderMenu = {
    id: 'ordersRoot',
    title: 'ORDERS',
    type: 'group',
    children: [
        {
            id: 'orders',
            title: 'Customer Orders',
            type: 'item',
            url: '/orders',
            breadcrumbs: false,
            icon: ShoppingCartOutlinedIcon
        }
    ]
}

export default OrderMenu