import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'

const WageMenu = {
    id: 'wagesRoot',
    title: 'WAGES',
    type: 'group',
    children: [
        {
            id: 'wages',
            title: 'Wages',
            type: 'item',
            url: '/wages',
            icon: AttachMoneyOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'wageBoard',
            title: 'Wages',
            type: 'item',
            url: '/wageBoard',
            icon: AttachMoneyOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'timesheets',
            title: 'Timesheets',
            type: 'item',
            url: '/timesheets',
            icon: AccessTimeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'timesheetBoard',
            title: 'Timesheets',
            type: 'item',
            url: '/timesheetBoard',
            icon: AccessTimeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'commission',
            title: 'Commission',
            type: 'item',
            url: '/commission',
            icon: MonetizationOnOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'commissionBoard',
            title: 'Commission',
            type: 'item',
            url: '/commissionBoard',
            icon: MonetizationOnOutlinedIcon,
            breadcrumbs: false
        }
    ]
}
export default WageMenu