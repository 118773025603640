import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined"

const StaffMenu = {
    id: 'staffRoot',
    title: 'STAFFS',
    type: 'group',
    icon: GroupAddOutlinedIcon,
    children: [
        {
            id: 'users',
            title: 'Staffs',
            type: 'item',
            icon: GroupAddOutlinedIcon,
            url: '/users'
        }
    ]
}
export default StaffMenu