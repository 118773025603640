import CategoryIcon from "@mui/icons-material/Category"
import StorefrontIcon from "@mui/icons-material/Storefront"

const ServiceMenu = {
    id: 'servicesRoot',
    title: 'SERVICES/PRODUCTS',
    type: 'group',
    children: [
        {
            id: 'categories',
            title: 'Category',
            type: 'item',
            url: '/categories',
            icon: CategoryIcon
        },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/products',
            icon: StorefrontIcon
        }
    ]
}
export default ServiceMenu