import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined"

const FinanceMenu = {
    id: 'financesRoot',
    title: 'FINANCES',
    type: 'group',
    children: [
        {
            id: 'bills',
            title: 'Bills',
            type: 'item',
            url: '/bills',
            breadcrumbs: false,
            icon: AttachMoneyOutlinedIcon
        },
        {
            id: 'outstanding',
            title: 'Outstanding',
            type: 'item',
            url: '/outstanding',
            breadcrumbs: false,
            icon: PaymentOutlinedIcon
        },
        {
            id: 'credits',
            title: 'Family Accounts',
            type: 'item',
            url: '/credits',
            breadcrumbs: false,
            icon: CardGiftcardOutlinedIcon
        }
    ]
}

export default FinanceMenu